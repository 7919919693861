<template>
  <div class="page-gray">
    <div v-if="null!==logisticsData">
      <div class="goodsItem" v-for="(orderItem,index) in logisticsData.logisticsProducts" :key="index">
        <div class="name">
          <div>{{ orderItem.subProductName }}</div>
        </div>
        <div class="goodsInfo">
          <img
              :src="orderItem.pic"
              alt=""
          />
          <div class="right">
            <div class="price">
              <span>¥</span>
              <span>{{ orderItem.price }}</span>
            </div>
            <div class="sku">规格：{{ orderItem.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ orderItem.num }}</div>
            <div class="tagList">
              <div class="tag"  v-show="orderItem.saleProduct.deliveryMode ==='2'">配送到校</div>
              <div class="tag" v-show="orderItem.saleProduct.deliveryMode ==='1'">快递到家</div>
            </div>
            <div class="time" v-show="orderItem.saleProduct.deliverGoodsTemp === '1'">下单{{orderItem.saleProduct.day}}天内发货：</div>
            <div class="time" v-show="orderItem.saleProduct.deliverGoodsTemp === '2'">预计配送时间：{{ orderItem.saleProduct.deliverGoodsContent }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom" v-if="logisticsData.logisticsTrajectory && logisticsData.logisticsTrajectory.length>0">
      <div class="head">
        <div class="left">
          <span v-if="null!==logisticsData">{{ logisticsData.kdName }}  {{ logisticsData.billNumber }}</span>
        </div>
        <div class="right" v-if="null!==logisticsData" @click="CopyFun(logisticsData.billNumber)">
          复制
        </div>
      </div>
      <div class="item address" v-if="null!==logisticsData">
        <div class="dateTime">
        </div>
        <div class="dot">
          <div class="text">
            收
          </div>
        </div>
        <div class="content">
          <div class="info" style="color: #868686;">【收货地址】{{ logisticsData.address }}</div>
        </div>
      </div>
      <div class="logList" v-if="null!==logisticsData">
        <!-- 固定显示的 -->
        <van-steps
            direction="vertical"
            finish-icon="success"
            :active="0"
            active-icon="success" active-color="#38f">
          <van-step v-for="(logistic,index) in logisticsData.logisticsTrajectory" :key="index">
            <div class="content">
              <div class="info" style="color: #868686;">{{ logistic.acceptTime }}</div>
              <div class="info" style="color: #868686;">{{ logistic.acceptStation }}</div>
            </div>
          </van-step>

        </van-steps>
      </div>

      <van-empty v-if="null === logisticsData" description="暂无物流" />
    </div>

    <div class="orderInfoCard" v-if="logisticsData.deliveryNote">
      <div class="top van-hairline--bottom">
        <div class="item">
          <div class="key" >出单日期</div>
          <div class="value">{{ logisticsData.deliveryNote.createTime }}</div>
        </div>
        <div class="item">
          <div class="key">出单人:</div>
          <div class="value">{{ logisticsData.deliveryNote.createBy }}</div>
        </div>
        <div class="item">
          <div class="key">收货人:</div>
          <div class="value gray">{{ logisticsData.deliveryNote.confirmedBy }}</div>
        </div>
        <div class="item">
          <div class="key">确认时间:</div>
          <div class="value">{{ order.deliveryNote.confirmedTime }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "Logistics",
  data(){
    return {
      orderId:"",
      logisticsData:{},
      statusList:['快递揽件','在途中','正在派件','已签收','派送失败','疑难件','退件签收'],
    }
  },
  mounted() {
    this.orderId = this.$route.params.id;
    this.init();
  },
  methods: {
    init(){
        //查询订单物流信息
      this.$http.get(this.baseMallUrl + `/h5_mall_order/queryShippingOrderLogisticsInfo/${this.orderId}`).then(res => {
        this.logisticsData = res.data;

        if(null !== this.logisticsData){
          this.logisticsData.logisticsTrajectory.forEach((item)=>{
            let date = new Date(item.acceptTime.replace(/-/g, '/'));

            var month = date.getMonth()+1;//月
            if (month < 10) {
              month = '0' + month; // 若月份小于10则在前面补上零
            }
            var day = date.getDate();//日
            if (day < 10) {
              day = '0' + day; // 若月份小于10则在前面补上零
            }
            var hours = date.getHours();//时
            if (hours < 10) {
              hours = '0' + hours; // 若月份小于10则在前面补上零
            }
            var min = date.getMinutes();//分
            if (min < 10) {
              min = '0' + min; // 若月份小于10则在前面补上零
            }
            item.day = month+"-"+day;
            item.time = hours+":"+min;
          })
        }
      })
    },
    CopyFun(content) {
      let oInput = document.createElement("input");
      oInput.value = content;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$toast.success('复制成功');
      oInput.remove();
    }
  },
}

</script>
<style lang="less" scoped>
.goodsItem {
  padding: 16px 30px;
  background-color: #fff;


  .name {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    div:first-child {
      font-size: 12px;
      color: #333333;
      line-height: 15px;
      width: 100%;
    }

    .state {
      line-height: 15px;
      font-size: 12px;
      color: #DE4547;

      &.black {
        color: #333333;
      }
    }
  }

  .buttonHandle {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 14px;

    .highlight {
      width: 60px;
      height: 20px;
      background: #FFD8DB;
      border-radius: 10px;
      line-height: 20px;
      font-size: 10px;
      color: #D74633;
      text-align: center;
    }

    .plain {
      width: 68px;
      height: 23px;
      border: 1px solid #999999;
      border-radius: 12px;

      line-height: 23px;
      text-align: center;
      font-size: 11px;
      color: #212121;
      line-height: 20px;
    }
  }
}

.goodsInfo {
  display: flex;
  align-items: center;
  margin-top: 9px;

  &.multiple {
    img {
      margin-right: 5px;
    }
  }

  img {
    width: 95px;
    height: 80px;
    border-radius: 5px;
    min-width: 95px;
    margin-right: 20px;
  }

  .right {
    .price {
      span {
        line-height: 1;
        font-weight: bold;
        color: #d53a26;

        &:last-child {
          font-size: 15px;
          margin-left: 3px;
        }

        &:first-child {
          font-size: 12px;
        }
      }
    }

    .sku {
      background: #eeeeee;
      border-radius: 2px;

      padding: 5px 10px;
      line-height: 10px;
      font-size: 10px;
      color: #666666;

      display: inline-block;
    }

    .tagList {
      margin: 7px 0;
      font-size: 0;

      .tag {
        display: inline-block;
        background: #ffffff;
        border: 1px solid #ff0000;
        border-radius: 3px;
        line-height: 10px;
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ff0000;

        padding: 2px 5px;
      }
    }

    .time {
      line-height: 10px;
      font-size: 10px;
      color: #e7ac58;
    }
  }
}

.bottom {
  margin-top: 10px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 48px;
    margin-bottom: 15px;

    .left {
      display: flex;
      align-items: center;

      img {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        margin-right: 7px;
      }

      span {
        font-size: 10px;
        font-weight: bold;
        color: #212121;
      }
    }

    .right {
      font-size: 10px;
      font-weight: bold;
      color: #999999;
    }
  }
}

.bottom {
  padding: 17px;
  background-color: #fff;
  margin-top: 10px;

  .item {
    display: flex;
    align-items: center;
    padding-bottom: 30px;

    &.address {

      .dateTime {
        width: 36px;
      }

      .dot {
        z-index: 2;

        .text {
          width: 24px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          background: #CDCDCD;
          border-radius: 50%;
          font-size: 12px;
          font-weight: bold;
          color: #FFFFFF;
          position: absolute;
          right: -12px;
          top: 0px;
        }
      }
    }

    &:last-child {
      .content {
        &::after {
          display: none;
        }
      }
    }

    &:first-child {
      .dot {
        .img {
          border: none;
          background: linear-gradient(90deg, #F9525E, #F9533E);
        }
      }
    }

    &:not(:first-child) {
      .content {
        .title, .info {
          color: #868686;
        }
      }
    }

    .dateTime {
      .date {
        font-size: 11px;
        font-weight: bold;
        color: #868686;
        line-height: 1;
      }

      .time {
        padding-left: 11px;
        font-size: 9px;
        font-weight: bold;
        color: #868686;
      }
    }

    .dot {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .img {
        height: 80%;
        width: 80%;

        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 2px solid #ddd;
        border-radius: 50%;
        position: absolute;
        right: -12px;
        top: -5px;
        z-index: 1;

        img {
          width: 12px;
          position: relative;
          z-index: 3;
        }
      }

      .circle {
        position: absolute;
        width: 12px;
        height: 12px;
        background: #DDDDDD;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        right: -6px;
        z-index: 12;
      }
    }

    .content {
      position: relative;
      padding-left: 20px;

      &::after {
        content: '';
        width: 1px;
        height: 60px;
        background-color: #DDDDDD;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
      }


      .title {
        font-size: 11px;
        font-weight: bold;
        color: #333333;
      }

      .info {
        font-size: 10px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
}
</style>
